/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* For animating between dark and light modes */
* {
    transition: color 300ms;
    transition: background-color 300ms;
    overscroll-behavior: none;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul ul ul {
    list-style-type: circle; /* Different bullet for the inner list */
    margin-left: 1rem;
}

.mt-screen {
    margin-top: 100vh;
}

@media print {
    @page {
        margin-top: 0;
    }

    header {
        position: static;
    }

    header::after {
        content: counter(page);
        counter-increment: page;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        page-break-after: always;
    }

    header:not(:first-of-type) {
        display: none;
    }
}
