.search-container {
    position: relative;
    display: flex;
    align-items: center;
}

.search-container .icon {
    position: absolute;
    cursor: pointer;
}

.search-container .icon.left {
    left: 10px;
}

.search-container .icon.right {
    right: 10px;
}

.search-container textarea {
    margin: 0;
    border-radius: 4px;
    width: 100%;
}

.search-container textarea:focus {
    outline: none;
}

.search-container input {
    margin: 0;
    border-radius: 4px;
    width: 100%;
}

.search-container input:focus {
    outline: none;
}

.search-container button {
    background: none;
    border: none;
    cursor: pointer;
}
